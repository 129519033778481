// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-dzierzawa-krok-po-kroku-js": () => import("./../../../src/pages/dzierzawa-krok-po-kroku.js" /* webpackChunkName: "component---src-pages-dzierzawa-krok-po-kroku-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jak-to-dziala-js": () => import("./../../../src/pages/jak-to-dziala.js" /* webpackChunkName: "component---src-pages-jak-to-dziala-js" */),
  "component---src-pages-kim-jestesmy-js": () => import("./../../../src/pages/kim-jestesmy.js" /* webpackChunkName: "component---src-pages-kim-jestesmy-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */)
}

